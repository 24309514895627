import { css } from "@emotion/react";
import {
  black,
  border,
  fontBlack,
  fontBody,
  boxHover,
} from "../../../../styles/common.css";

export default css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .item-wrapper {
    display: flex;
    width: 80%;
    cursor: pointer;

    .product-contents {
      display: flex;
      flex-direction: column;
    }
  }

  .product {
    &-image {
      min-width: 100px;
      height: 100px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 20px;
      border-radius: 6px;
    }

    &-contents {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      justify-content: center;
    }
    &-tit {
      font-weight: 800;
      font-size: 1.125em;
      margin-bottom: 4px;
      line-height: 24px;
      color: ${fontBlack};
      width: 424px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-desc {
      font-size: 0.875em;
      font-weight: 500;
      line-height: 1.5;
      color: ${fontBody};
      margin-bottom: 16px;
      width: 424px;
      white-space: normal;
      word-wrap: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .product-item-footer {
    display: flex;
  }

  .tag {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    margin-left: 16px;

    span {
      padding: 0 8px;

      background-color: #eeeeee;
      border-radius: 30px;
    }
  }

  .count-container {
    display: flex;

    &.mobile {
      display: none;
    }
  }

  .view-count {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .comment-count {
    display: flex;
    align-items: center;
    height: 24px;
  }
  .count-txt {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 500;
    color: ${fontBody};
  }

  .like-button {
    button {
      border: ${border};
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      border-radius: 20px;
    }

    &-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .clap-icon {
        font-size: 1.7em;
      }
      .like-count {
        font-weight: 700;
        font-size: 1.1em;
        line-height: 24px;

        /* color: ${fontBlack}; */
      }
    }
  }

  @media screen and (max-width: 1212px) {
    .product-image {
      min-width: 80px;
      height: 80px;
    }
  }

  @media screen and (max-width: 800px) {
    .item-wrapper {
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      .product-image {
        min-width: 40px;
        height: 40px;
        margin: 0;
      }

      .product-contents {
        max-width: 85%;
        margin-left: 16px;

        .product-tit {
          max-width: 100%;
          margin: 0 0 2px;
          font-size: 16px;
          line-height: 20px;
        }
        .product-desc {
          max-width: 100%;
          margin-bottom: 12px;

          font-size: 13px;
          -webkit-line-clamp: 1;
        }
      }
    }
    .product-item-footer {
      margin-bottom: 8px;
      margin-left: 0;
    }
    .tag {
      margin-left: 0;
    }
    .pc {
      display: none;
    }

    .product-item-user-relation {
      /* border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 24px; */
      margin-top: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .count-container {
        &.mobile {
          display: flex;
          .comment-count {
            cursor: pointer;
          }
        }
      }

      .like-button {
        padding: 4px 12px;

        button {
          width: 56px;
          height: 24px;

          border: none;

          .like-button-icon {
            flex-direction: row;

            .clap-icon {
              font-size: 18px;
              margin-right: 8px;
            }
            .like-count {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
`;
