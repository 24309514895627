import { css } from "@emotion/react";
import { fontBlack, fontBody } from "../../../../styles/common.css";

export default css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 32px;
  transform: translateY(1px);

  > div {
    padding: 4px 0;
    cursor: pointer;
    font-weight: 800;
    color: #777;
    line-height: 24px;
    margin-bottom: 4px;
    /* transition: 0.2s ease; */
    border-radius: 30px;

    &.active-tab,
    &:hover {
      background-color: ${fontBlack};
      color: #fff;
    }
  }

  &.border-type {
    margin-bottom: 0;
    > div {
      margin-bottom: 0;
      &.active-tab,
      &:hover {
        border-bottom: 3px solid ${fontBlack};
        padding-bottom: 12px;
        color: ${fontBlack};
        background-color: unset;
        border-radius: 0px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    min-width: 100%;
    gap: 0px;
    justify-content: space-around;

    > div {
      white-space: pre;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;
