import { useEffect, useState } from "react";
import Router from "next/router";
import Prensenter from "./Presenter";
import { PATH_PRODUCT } from "../../paths";

import { getProductList, postProductCount } from "../../apis/product.api";
import { userInfo } from "../../recoils/user";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { productCategoryTabs } from "../../utils/json/tab.json";
import { FORM_TYPE } from "../../utils/constants";
import { likeAPI } from "../../utils/common-api";

import { widgetDataStore } from "../../recoils/widgetData";
import { widgetTextDataStore } from "../../recoils/widgetTextData";
import { widgetDataTypeStore } from "../../recoils/widgetDataType";
import { PRODUCT_COUNT_TYPE } from "../../utils/product.constants";
import { useHandleLoginConfirm } from "../../utils/login.function";
import { PRODUCT_FILTER_OPTIONS, SORT_OPTIONS } from "../../utils/json/sort.json";

export default function ProductContainer({
	search = null,
	showHeader = true,
	onHandleTotal,
}) {
	const user = useRecoilValue(userInfo);
	const [id, setId] = useState(null);
	const [list, setList] = useState([]);
	const [query, setQuery] = useState({
		offset: 0,
		limit: 10,
	});
	const [type, setType] = useState("init");
	const [isLast, setIsLast] = useState(false);

	const [currentTab, setCurrentTab] = useState([PRODUCT_FILTER_OPTIONS[0]]);
	const [sortValue, setSortValue] = useState(SORT_OPTIONS[0]);

	const setWdidgetData = useSetRecoilState(widgetDataStore);
	const setWidgetTextData = useSetRecoilState(widgetTextDataStore);
	const setDataType = useSetRecoilState(widgetDataTypeStore);
	const loginConfirm = useHandleLoginConfirm();

	let enabled = !!id;

	if (search !== null) enabled = !!id && !!search;

	const onChangeTab = ({ target, selectedValue }) => {
		if (selectedValue.value === "all") {
			setCurrentTab([PRODUCT_FILTER_OPTIONS[0]]);
		} else {
			const values = target.value;
			const currValues = values.filter((item) => item.value !== "all");
			setCurrentTab(currValues);
		}

		setType("init");
		setQuery({
			offset: 0,
			limit: 10,
		});
	};

	function onChangeSort(item) {
		setSortValue(item.target.value[0]);
		setType("init");
		setQuery({
			limit: 10,
			offset: 0,
		});
	}

	async function productCountHandle(productId) {
		try {
			return await postProductCount(productId, PRODUCT_COUNT_TYPE.VIEW, user?.token);
		} catch (error) {
			console.log(error);
		}
	}

	const routeHandle = async (type, product) => {
		if (user?.id) query = user.id;

		productCountHandle(product?.id);

		Router.push(`${PATH_PRODUCT.detail(product?.productUniqueId).url}?tab=0`);
	};

	async function getProductListFn(id, query, tab, type, sort) {
		const filterValue = tab.reduce((acc, cur) => {
			if (cur.value?.includes("all")) return "all";
			if (acc === "") return cur.value;
			return acc + "," + cur.value;
		}, "");

		query.type = filterValue;
		query.sort = sort;

		if (search !== null) query.search = search;

		const { data } = await getProductList(id, query);

		if (type) setList(data.list);
		else setList((prev) => prev.concat(data.list));

		setIsLast(data.isLast);
		setType(null);

		if (onHandleTotal) onHandleTotal(data.total);
	}

	async function clickLike(product) {
		try {
			const ids = product?.users.map((u) => u.id);
			await likeAPI(user?.token, product?.id, "product", ids);
			setList((prev) => {
				return prev.map((p) => {
					if (p.id === product.id) {
						p.likes = !p.isLike ? p.likes + 1 : p.likes - 1;
						p.isLike = !p.isLike;
					}
					return p;
				});
			});
		} catch (message) {
			// alert(message);
			loginConfirm();
		}
	}

	function queryHandle() {
		setQuery((prev) => {
			return {
				...prev,
				offset: prev.offset + 1,
			};
		});
	}

	useEffect(() => {
		if (user) {
			setId(user?.id);
		} else {
			setId("public");
		}
	}, [user]);

	// init
	useEffect(() => {
		if (enabled) getProductListFn(id, query, currentTab, "init", sortValue?.value);

		if (search === "") {
			setList([]);
			setIsLast(true);
			if (onHandleTotal) onHandleTotal(0);
		}
	}, [id, currentTab, search, sortValue?.value]);

	useEffect(() => {
		if (id && type === null)
			getProductListFn(id, query, currentTab, null, sortValue?.value);
	}, [query, sortValue?.value]);

	useEffect(() => {
		setWdidgetData(null);
		setWidgetTextData(null);
		setDataType(FORM_TYPE.GET);
	}, []);

	return (
		<Prensenter
			route={(type, product) => routeHandle(type, product)}
			list={list}
			clickLike={clickLike}
			queryHandle={queryHandle}
			query={query}
			isLast={isLast}
			showHeader={showHeader}
			tabs={PRODUCT_FILTER_OPTIONS}
			sortOptions={SORT_OPTIONS}
			onChangeCategory={onChangeTab}
			onChangeSort={onChangeSort}
			sortValue={sortValue}
			categoryValue={currentTab}
		/>
	);
}

// 10min
