import { css } from "@emotion/react";
import { border, catYellow } from "../../../styles/common.css";

export default css`
  .product-header {
    margin-top: 36px;
    position: sticky;
    top: -95px;
    background-color: #ffffff;
    z-index: 1;
    .tab {
      gap: 4px;
      .tab-item {
        padding: 4px 12px;

        /* 2번째 탭 */
        :nth-of-type(2)::after {
          content: " 🔥";
        }
      }
    }
  }
  .product-list-container {
    width: 100%;
    /* margin: 0 120px; */

    ul {
      width: 100%;

      .product-item {
        border-bottom: ${border};
        padding: 32px 0;

        &:hover {
          background-color: rgba(0, 0, 0, 0.02);
          transition: 0.2s ease;
        }
      }
      .item-container {
        width: 690px;
        margin: 0 auto;
        align-items: flex-start;
      }
    }
  }

  @media screen and (max-width: 1212px) {
    .product-wrapper {
      max-width: 560px;
    }
  }

  @media screen and (max-width: 800px) {
    .product-header {
      margin-top: 107px;
      width: 100%;

      /* top: -65px !important; */
      top: -225px !important;

      > div {
        width: 90%;
      }

      .tab {
        margin-bottom: 4px;
        justify-content: unset;

        .tab-item {
          padding: 4px 8px;
        }
      }
    }
    .product-list-container {
      width: 90%;

      ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 20px;

        .product-item {
          padding: 16px 16px 8px;
          border: 1px solid rgba(0, 0, 0, 0.04);
          box-shadow: 0px 8px 24px -12px rgba(57, 36, 12, 0.2);
          border-radius: 12px;

          .item-container {
            width: 100%;

            justify-content: flex-end;
          }
        }
      }
    }
  }
`;
