import style from "./tabs.css";
import { useEffect, useState } from "react";
import { TAB_TYPE } from "../../../utils/type";

export default function TabLink({
  tabs = [],
  onChange,
  defaultValue = "0",
  type,
  multiselect = false,
}) {
  const [currentTab, setCurrentTab] = useState(defaultValue);

  useEffect(() => {
    setCurrentTab(defaultValue);
  }, [defaultValue]);

  const handleOnClick = (value) => {
    if (multiselect) {
      setCurrentTab((prev) => {
        if (value === "all") {
          return value;
        } else {
          if (prev.includes("all")) {
            return value;
          } else {
            if (!prev.includes(",") && prev === value) return value;

            const prevArr = prev.split(",");
            const index = prevArr.indexOf(value);
            if (index > -1) {
              prevArr.splice(index, 1);
            } else {
              prevArr.push(value);
            }
            const result = prevArr.join(",");

            value = result;
            return result;
          }
        }
      });
    } else {
      setCurrentTab(value);
    }
    if (onChange) onChange(value);
  };

  const classNameValue = classNameHandle(type);

  return (
    <div className={classNameValue} css={style}>
      {tabs.map((tab) => {
        const title = tab.title ?? tab.name;
        const value = tab.value ?? title;

        let className = currentTab == value ? "active-tab" : "";
        if (multiselect && currentTab.split(",").indexOf(value) > -1) {
          className += " active-tab";
        }

        return (
          <div
            key={title}
            className={`tab-item ${className}`}
            onClick={() => handleOnClick(value)}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
}

function classNameHandle(type, className) {
  let classNameValue = "tab";

  if (type === TAB_TYPE.BORDER) classNameValue += " border-type";

  return classNameValue;
}
