import { css } from "@emotion/react";
import { border, wrap } from "../../../../styles/common.css";

export default css`
  position: sticky;
  top: -62px;
  margin-top: 18px;
  border-bottom: ${border};
  width: 100%;
  align-items: center;
  background-color: #fff;
  z-index: 1;

  > div {
    width: 690px;

    .pageTit {
      display: flex;
      flex-direction: column;

      .PageTit01 {
        display: flex;
        flex-direction: row;
        align-items: center;
        h1.first-h1 {
          margin-bottom: 0;
        }
        span {
          img {
            margin: 0 !important;
          }
        }
      }
    }

    h1 {
      white-space: pre;
      line-height: 48px;
      margin-bottom: 28px;
      font-weight: 900;
    }
  }

  @media screen and (max-width: 800px) {
    > div {
      width: 100%;
    }
  }
`;
