import Router from "next/router";
import CommentIcon from "../../../assets/icons/Comment.icon";
import { PATH_PRODUCT } from "../../../paths";
import ButtonIcon from "../../common/Button/Button.Icon";

import productDefaultThumbnail from "../../../assets/images/productDefaultThumbnail.png";

import style from "./item.css";
import EyeIcon from "../../../assets/icons/Eye.icon";
const ShowTag = ({ tags }) => {
  return (
    <div className="tag">
      {tags.map((tag, index) => {
        return <span key={`tag_${tag.id}`}>{tag.name}</span>;
      })}
    </div>
  );
};
// BUG: 태그 추가시 로딩이 계속 돔
export default function ProductItem({
  productImage,
  name,
  oneLineDescription,
  likes,
  tags,
  isLike,
  id,
  thumbnail,

  onRouter,
  clickLike,
  name_en,
  mentionCount = 0,
  viewCount = 0,
}) {
  return (
    <div className="item-container" css={style}>
      <div className="item-wrapper">
        <div
          className="product-image"
          style={{
            backgroundImage: `url(${thumbnail ?? productDefaultThumbnail.src})`,
          }}
          onClick={onRouter}
        />
        <div className="product-contents" onClick={onRouter}>
          <div className="product-tit">
            {name_en} - {name}
          </div>
          <div className="product-desc" onClick={onRouter}>
            {oneLineDescription}
          </div>
          <div className="product-item-footer">
            <div className="view-count pc">
              <EyeIcon />
              <span className="count-txt">{viewCount}</span>
            </div>
            <div className="comment-count pc" onClick={onRouter}>
              <CommentIcon />
              <span className="count-txt pc">{mentionCount}</span>
            </div>
            <ShowTag tags={tags} />
          </div>
        </div>
      </div>
      <div className="product-item-user-relation">
        <div className="count-container mobile">
          <div className="view-count">
            <EyeIcon />
            <span className="count-txt">{viewCount}</span>
          </div>
          <div className="comment-count" onClick={onRouter}>
            <CommentIcon />
            <span className="count-txt">{mentionCount}</span>
          </div>
        </div>
        <ButtonIcon
          type="button"
          className="like-button action-btn"
          onClick={clickLike}
          isActive={isLike}
          id={`like_prd_${id}`}
          ariaLabel={"프로덕트 좋아요 버튼"}
          icon={
            <div className="like-button-icon">
              <p className="clap-icon">👏</p>
              <p className="like-count">{likes}</p>
            </div>
          }
        />
      </div>
    </div>
  );
}
