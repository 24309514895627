import Header from "../common/Header";
import ProductItem from "./Item";
import css from "./product.css";
import productDefaultThumbnail from "../../assets/images/productDefaultThumbnail.png";
import { FORM_TYPE } from "../../utils/constants";
import InfiniteScroll from "../common/InfiniteScroll";
import ClapAnimationImg from "../../assets/images/clap.webp";

export default function ProductPresenter({
  route,
  list,
  tabs,
  clickLike,
  queryHandle,
  query,
  isLast,
  showHeader,
  sortOptions,
  onChangeCategory,
  onChangeSort,
  sortValue,
  categoryValue,
}) {
  return (
    <div css={css} className="content-wrapper">
      {showHeader && (
        <Header
          title={`팬 모집 중! `}
          title2={`프로덕트 리스트`}
          img={ClapAnimationImg}
          imgWidth={40}
          imgHeight={40}
          tabs={tabs}
          multiselect={true}
          className="product-header"
          top="-96px"
          sortOptions={sortOptions}
          onChangeCategory={onChangeCategory}
          onChangeSort={onChangeSort}
          sortValue={sortValue}
          categoryValue={categoryValue}
        />
      )}
      <div className="product-list-container">
        <InfiniteScroll
          onChangeQuery={queryHandle}
          query={query}
          isLast={isLast}
        >
          <ul>
            {list.map((product) => {
              const thumbnail =
                product?.productImage ?? productDefaultThumbnail?.src;

              return (
                <li className="product-item" key={`product_${product.id}`}>
                  <ProductItem
                    {...product}
                    thumbnail={thumbnail}
                    onRouter={() => route(FORM_TYPE.GET, product)}
                    clickLike={() => clickLike(product)}
                  />
                </li>
              );
            })}
          </ul>
        </InfiniteScroll>
      </div>
    </div>
  );
}
